import { Database } from "./database.types";
import { Model, ModelAttributes, model, modelConfig, reference } from "@mixitone/oom";
import Token from "./Token";

const config = modelConfig(
  {
    account_id: {
      type: "string",
    },
    created_at: {
      type: "datetime",
    },
    is_deleted: {
      type: "boolean",
    },
    status: {
      type: "enum",
      enumType: "pending" as Database["public"]["Enums"]["token_notification_status"],
    },
    token_id: {
      type: "string",
    },
    updated_at: {
      type: "datetime",
    },
  },
  {},
  {
    token: reference<any>(() => Token as any, "token_id"),
  },
);

interface TokenNotification extends ModelAttributes<typeof config> {}

@model("token_notifications", config)
class TokenNotification extends Model<typeof config> {
  get token() {
    if (this.data?.get("tokens")) {
      return this.data.get("tokens").token_value;
    }

    return undefined;
  }
}

export default TokenNotification;
