import { Spinner } from "@mixitone/components";
import { ApplicationView } from "@mixitone/mvc";
import { decodeJwt } from "@mixitone/util/supabase";
import { Link } from "react-router-dom";
import { Layout } from "../Components/Layout";
import { injectParams } from "../lib/injectParams";
import { PortalController } from "../lib/PortalController";
import { createSupabase } from "../supabaseClient";

interface State {
  loading: boolean;
  expired: boolean;
  success: boolean;
}
interface Props {
  params: { token: string };
}

class MagicLinkPageController extends PortalController<State, Props> {
  async initialize(props: Props) {
    this.state.loading = true;

    const supabase = createSupabase();
    const response = await supabase.functions.invoke("player-portal", {
      body: JSON.stringify({
        action: "signInWithToken",
        token: props.params.token,
      }),
    });
    if (response.error) {
      console.log(response.error);
      this.state.expired = true;
    } else if (!response.data?.access_token) {
      console.log("No access token found in response");
      this.state.expired = true;
    } else {
      const { access_token, refresh_token } = response.data;

      const session = await supabase.auth.setSession({
        access_token,
        refresh_token,
      });
      if (session.error) {
        console.log(session.error);
        this.state.expired = true;
      } else {
        this.state.success = true;
        this.actionSuccess(props.params.token);
      }
    }

    this.state.loading = false;
  }

  actionSuccess(token: string) {
    const jwt = decodeJwt(token);
    if (jwt && "redirectTo" in jwt) {
      this.navigate(jwt.redirectTo as string);
    } else {
      this.navigate("/in/home");
    }
  }
}

const ControlledMagicLinkPage = () => {
  const controller = MagicLinkPageController.use();
  const { loading, expired } = controller.state;

  if (expired) {
    return (
      <Layout title="Sign in">
        <p>
          Your link has expired. <Link to="/">Request a new one</Link>
        </p>
      </Layout>
    );
  }

  return <Layout title="Sign in">{loading && <Spinner size={32} />}</Layout>;
};

export const MagicLinkPage = injectParams(
  MagicLinkPageController.scope(ApplicationView(ControlledMagicLinkPage)),
);
