import ApplicationController from "@mixitone/mvc";
import { ControllerState } from "@mixitone/mvc";

/**
 * Toggle a state property on and off during the execution of the method
 */
export function withStateToggle<T extends ApplicationController<any, any, any>>(
  statePropertyKey: keyof ControllerState<T>,
  options: { returnIfTrue?: boolean; delayedOff?: number } = {},
) {
  return function (_target: T, _propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;

    descriptor.value = async function (this: T, ...args: any[]) {
      if (options.returnIfTrue && this.state[statePropertyKey]) {
        return;
      }

      this.state[statePropertyKey] = true;
      try {
        const result = await originalMethod.apply(this, args);
        return result;
      } finally {
        if (options.delayedOff) {
          setTimeout(() => {
            this.state[statePropertyKey] = false;
          }, options.delayedOff);
        } else {
          this.state[statePropertyKey] = false;
        }
      }
    };
  };
}
