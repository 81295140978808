import { Input } from "@mixitone/components";
import React, { useEffect, useMemo, useState } from "react";

type ObfuscatedInputProps = Omit<React.ComponentProps<typeof Input>, "value"> & {
  value: string;
  maskChar?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

function maskValue(val: string, maskChar: string) {
  if (val.length <= 4) return val;
  const start = val.slice(0, 2);
  const end = val.slice(-2);
  const maskedMiddle = maskChar.repeat(val.length - 4);
  return `${start}${maskedMiddle}${end}`;
}

export const ObfuscatedInput: React.FC<ObfuscatedInputProps> = ({
  value,
  onChange,
  maskChar = "*",
  ...props
}) => {
  const inputRef = React.createRef<HTMLInputElement>();
  const [unmaskedValue, setUnmaskedValue] = useState(value);
  const [editing, setEditing] = useState(false);
  const maskedValue = useMemo(() => maskValue(unmaskedValue, maskChar), [unmaskedValue, maskChar]);

  useEffect(() => {
    if (!editing) {
      setUnmaskedValue(value);
    }
  }, [value, editing]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUnmaskedValue(e.target.value);
    onChange(e);
  };

  const handleFocus = () => {
    setUnmaskedValue("");
    setEditing(true);
  };

  const handleBlur = () => {
    setEditing(false);
  };

  return (
    <Input
      ref={inputRef}
      {...props}
      value={editing ? unmaskedValue : maskedValue}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  );
};
