import React from "react";
import { useParams, Params } from "react-router-dom";

type InjectedProps = {
  params: Readonly<Params<string>>;
};

export function injectParams<P extends InjectedProps>(
  Component: React.ComponentType<P>,
): React.FC<Omit<P, "params">> {
  return (props: Omit<P, "params">) => {
    const params = useParams();
    return <Component {...(props as P)} params={params} />;
  };
}
