const colors = ["#FFD700", "#FF4500", "#00BFFF", "#FF79A4", "#32CD32", "#FF7F00", "#9932CC", "#40E0D0"];

interface Props {
  title: React.ReactNode;
  colorIndex?: number;
}

export const DashLayout: React.FC<React.PropsWithChildren<Props>> = ({ title, colorIndex = 0, children }) => {
  const color = colors[colorIndex % colors.length];

  return (
    <div className="flex flex-col w-full h-full max-w-md dash-tabs">
      <div
        style={{ backgroundColor: color }}
        className="z-10 p-4 -mx-1 -mt-2 -mb-2 text-2xl font-thin text-center text-black transform shadow-lg dash-current-tab font-bevan shadow-brand-800/40"
      >
        {title}
      </div>
      <div className="h-full px-6 pt-8 overflow-y-auto bg-gray-100 rounded-b-lg shadow-xl">{children}</div>
    </div>
  );
};
