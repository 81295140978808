import { SupabaseClient } from "@supabase/supabase-js";
import { tryJsonParse } from "./util";

export interface SupabaseJwt {
  email: string;
  app_metadata: {
    provider: string;
    providers: string[];
  };
  user_metadata: {
    name: string;
  };
  role: string;
  user_role?: string;
  aal: string;
  amr: Array<{
    method: string;
    timestamp: number;
  }>;
  iat: number;
  exp: number;
  aud: string;
  sub: string;
}

type Response<T> =
  | {
      data: T;
      error: null;
    }
  | {
      data: T;
      error: any;
    }
  | {
      data: T;
      error: null;
    };

type ResponseReturn<R extends Response<unknown>> = R extends Response<infer T> ? T : never;

export async function supabaseResponse<
  T extends Response<any>,
  R extends ResponseReturn<T>,
  D extends keyof R,
>(dataKey: D, request: Promise<T>): Promise<NonNullable<R[D]>> {
  const response = await request;

  if (response.error) {
    throw response.error;
  }

  const data = response.data[dataKey];

  if (!data) {
    throw new Error(`No data found for key ${String(dataKey)}`);
  }

  return response.data[dataKey];
}

export async function getJwt(supabase: SupabaseClient): Promise<SupabaseJwt> {
  const session = await supabaseResponse("session", supabase.auth.getSession());
  const token = session.access_token;
  const jwt = decodeJwt(token);
  return jwt as SupabaseJwt;
}

export function decodeJwt(token: string): SupabaseJwt {
  return tryJsonParse(atob(token.split(".")[1])) as SupabaseJwt;
}
