import { arrow, FloatingArrow, shift, useFloating } from "@floating-ui/react";
import { Button, DropdownMenu, DropdownMenuItem, GroupDot } from "@mixitone/components";
import { Group } from "@mixitone/models";
import { ApplicationView } from "@mixitone/mvc";
import { useCallback, useRef, useState } from "react";

interface Props {
  groups: Group[];
  value: Group;
  onChange(group: Group): void;
  className?: string;
  disabled?: boolean;
  spinner?: boolean;
}

const ControlledGroupDropdown: React.FC<Props> = ({
  groups,
  value,
  onChange,
  className,
  disabled,
  spinner,
}) => {
  const [open, setOpen] = useState(false);
  const arrowRef = useRef(null);
  const { refs, floatingStyles, update, context } = useFloating({
    middleware: [shift(), arrow({ element: arrowRef })],
  });

  const handleItemClick = useCallback((group: Group) => {
    setOpen(false);
    onChange(group);
  }, []);

  return (
    <>
      <Button
        kind="dropdown"
        ref={refs.setReference}
        disabled={disabled}
        spinner={spinner}
        onClick={() => {
          update();
          setOpen(true);
        }}
        className={className}
      >
        <div className="flex items-center gap-2">
          <GroupDot group={value} size={24} />
          {value.name}
        </div>
      </Button>
      <DropdownMenu
        dropRef={refs.setFloating}
        open={open}
        onClose={() => setOpen(false)}
        style={floatingStyles}
      >
        <FloatingArrow ref={arrowRef} context={context} fill="white" />
        {groups.map((group) => (
          <DropdownMenuItem
            key={group.domKey()}
            className="flex items-center gap-2 hover:no-underline"
            onClick={handleItemClick}
            value={group}
          >
            <GroupDot group={group} size={20} />
            {group.name}
          </DropdownMenuItem>
        ))}
      </DropdownMenu>
    </>
  );
};

const GroupDropdown = ApplicationView(ControlledGroupDropdown);
export { GroupDropdown };
