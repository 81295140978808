import { Model, ModelAttributes, model, modelConfig, reference } from "@mixitone/oom";
import AccountUser from "./AccountUser";

const config = modelConfig(
  {
    account_id: {
      type: "string",
    },
    account_user_id: {
      type: "string",
    },
    associated_id: {
      type: "string",
    },
    associated_table: {
      type: "string",
    },
    balance_after: {
      type: "number",
    },
    balance_before: {
      type: "number",
    },
    created_at: {
      type: "datetime",
    },
    credit_amount: {
      type: "number",
    },
    description: {
      type: "string",
    },
    is_deleted: {
      type: "boolean",
    },
    synced_with_credits: {
      type: "boolean",
    },
    updated_at: {
      type: "datetime",
    },
  },
  {
    // Add collections here if needed
  },
  {
    accountUser: reference<AccountUser>(() => AccountUser, "account_user_id"),
  },
);

interface CreditSpent extends ModelAttributes<typeof config> {}

@model("credit_spents", config)
class CreditSpent extends Model<typeof config> {}

export default CreditSpent;
