import { Brand } from "@mixitone/components";
import ApplicationController from "@mixitone/mvc";
import React, { useMemo } from "react";
import { NavigateOptions, Outlet, useNavigate } from "react-router-dom";

interface Props {
  navigate: ReturnType<typeof useNavigate>;
}

export class AppController extends ApplicationController<{}, Props> {
  private _navigate!: ReturnType<typeof useNavigate>;

  async initialize(props: Props) {
    this._navigate = props.navigate;
  }

  navigate(to: string, options?: NavigateOptions) {
    this._navigate(to, options);
  }
}

const ControlledRoot: React.FC = () => {
  return (
    <div className="flex items-center justify-center h-screen min-h-screen px-4 pt-16 pb-4 bg-slate-900 bg-gradient-to-r from-slate-800 to-slate-900">
      <Outlet />
      <a className="absolute left-4 top-4" href={import.meta.env.VITE_WWW}>
        <Brand color="dark" />
      </a>
    </div>
  );
};

const Root = AppController.scope(ControlledRoot);

const App = () => {
  const navigate = useNavigate();
  const root = useMemo(() => <Root navigate={navigate} />, [navigate]);
  return root;
};

export { App };
