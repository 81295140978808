export function ListSkeleton({ itemCount }: { itemCount: number }) {
  return (
    <ul className="divide-y divide-gray-100">
      {Array.from({ length: itemCount }).map((_, index) => (
        <li key={`skel-${index}`} className="flex px-4 py-2 space-x-4 animate-pulse">
          <div className="flex-1 space-y-2">
            <div className="h-4 bg-gray-200 rounded"></div>
            <div className="w-5/6 h-3 bg-gray-200 rounded"></div>
          </div>
          <div className="w-20 h-4 bg-gray-200 rounded"></div>
        </li>
      ))}
    </ul>
  );
}
