import { Spinner } from "@mixitone/components/index";
import { ApplicationView } from "@mixitone/mvc";
import { Layout } from "../Components/Layout";
import { injectParams } from "../lib/injectParams";
import { PortalController } from "../lib/PortalController";
import { createSupabase } from "../supabaseClient";
import portalFunctions from "../lib/portalFunctions";
import * as Sentry from "@sentry/react";

interface Props {
  params: {
    tag: string;
  };
}

interface State {
  loading: boolean;
  notFound: boolean;
  tag: string;
}

class TagLandingPageController extends PortalController<State, Props> {
  get initialState(): State {
    return {
      loading: true,
      notFound: false,
      tag: "",
    };
  }

  async initialize(props: Props) {
    this.state.tag = props.params.tag;
    this.signIn();
  }

  async signIn() {
    const supabase = createSupabase();
    const session = await supabase.auth.getSession();

    if (session) {
      await supabase.auth.signOut();
    }

    try {
      const response = await portalFunctions.signInWithNFC({
        tag: this.state.tag,
      });
      const { access_token } = response;
      const setSessionResponse = await supabase.auth.setSession({
        access_token,
        refresh_token: "NULL",
      });

      if (setSessionResponse.error) {
        console.log(setSessionResponse.error);
        this.setState({ loading: false, notFound: true });
        return;
      }

      await supabase.auth.stopAutoRefresh();
      this.navigate("/in/home");
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
      this.setState({ loading: false, notFound: true });
    }
  }
}

const ControlledTagLandingPage = () => {
  const controller = TagLandingPageController.use();
  const { loading, notFound } = controller.state;

  return (
    <Layout title={notFound ? "Tag not found" : "Signing in..."}>
      {loading && (
        <div className="flex justify-center w-full">
          <Spinner size={64} />
        </div>
      )}
      {notFound && (
        <div className="text-center">
          <p className="text-lg">The tag you scanned was not found. Contact your club admin.</p>
          <p>
            <a href="/" className="text-brand-600">
              Home
            </a>
          </p>
        </div>
      )}
    </Layout>
  );
};

const TagLandingPage = injectParams(
  TagLandingPageController.scope(ApplicationView(ControlledTagLandingPage)),
);
export { TagLandingPage };
