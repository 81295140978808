import { Button, Input, bindController } from "@mixitone/components";
import ApplicationController, { ApplicationView } from "@mixitone/mvc";
import React, { FormEvent } from "react";
import { Layout } from "../Components/Layout";
import portalFunctions from "../lib/portalFunctions";
// import { NfcManager, NfcTech } from 'react-native-nfc-manager';

interface State {
  email: string;
  sendingEmail: boolean;
  emailSent: boolean;
  emailLink?: string;
  nfcAvailable: boolean;
  scanning: boolean;
  scanError: string;
  serialNumber: string;
}

class ActivationPageController extends ApplicationController<State> {
  async initialize(_props: unknown) {
    this.state.nfcAvailable = "NDEFReader" in window;
  }

  convertNFCHexToBase64(hexString: string) {
    // Remove colons
    const hexWithoutColons = hexString.replace(/:/g, "");
    const parsedHex = hexWithoutColons.match(/.{1,2}/g)?.map((byte) => parseInt(byte, 16));

    if (!parsedHex) {
      throw new Error("Invalid hex string");
    }

    // Convert hex to bytes
    const bytes = new Uint8Array(parsedHex);

    // Encode to base64
    return btoa(String.fromCharCode(...bytes)).replace(/=/g, ""); // Remove padding to match STANDARD_NO_PAD
  }

  ndef?: NDEFReader;
  async actionScan() {
    if (!this.ndef) {
      this.ndef = new NDEFReader();
    }

    try {
      await this.ndef.scan();
      this.state.scanning = true;

      this.ndef.onreadingerror = () => {
        this.state.scanError = "Error reading NFC tag.";
      };
      this.ndef.onreading = (event) => {
        this.state.serialNumber = event.serialNumber;
        window.location.href = `/tag/${event.serialNumber}`;
      };
    } catch (error) {
      if (error instanceof Error) {
        this.state.scanError = error.message;
      }
    }
  }

  async actionSubmitEmail() {
    this.state.sendingEmail = true;
    const response = await portalFunctions.sendSignInEmail({ signInWith: { email: this.state.email } });

    if (response.success) {
      this.state.emailSent = true;
      this.state.emailLink = response.action_link;
    }

    this.state.sendingEmail = false;
  }
}

const ControlledActivationPage: React.FC = () => {
  const controller = ActivationPageController.use();
  const { nfcAvailable, scanning, scanError, serialNumber, emailSent, emailLink, sendingEmail } =
    controller.state;

  const handleEmailSubmit = (event: FormEvent) => {
    event.preventDefault();
    controller.actionSubmitEmail();
  };

  const handleNfcScan = async () => {
    controller.actionScan();
  };

  if (emailSent) {
    return (
      <Layout title="Player portal">
        {emailLink && (
          <p className="mb-6 text-gray-600">
            Click <a href={emailLink}>here</a> to access your profile.
          </p>
        )}
        {!emailLink && (
          <p className="mb-6 text-gray-600">Check your email for a link to access your profile.</p>
        )}
      </Layout>
    );
  }

  return (
    <Layout title="Club membership">
      <>
        <div className="mb-6">
          <h2 className="mb-2 text-lg font-semibold">Use your club tag</h2>
          {scanError && <p className="mb-4 text-red-500">{scanError}</p>}
          {serialNumber && <p className="mb-4 text-gray-500">Serial number: {serialNumber}</p>}
          <>
            <p className="mb-4 text-sm text-gray-500">
              If you have an NFC-enabled club tag, you can use it to quickly access your account.
            </p>

            {!nfcAvailable && (
              <p className="mb-4 text-sm text-gray-500">
                Hold your tag to the back of your iOS or Android device to scan it.
              </p>
            )}
          </>
          {nfcAvailable && (
            <Button onClick={handleNfcScan} className="w-full" disabled={scanning}>
              Scan now
            </Button>
          )}
        </div>
        <div className="mb-6">
          <div className="relative">
            <hr className="border-t border-gray-300" />
            <span className="absolute px-2 text-gray-500 transform -translate-x-1/2 -translate-y-1/2 bg-white left-1/2 top-1/2">
              OR
            </span>
          </div>
        </div>
      </>
      <div className="">
        <label className="mb-2 text-lg font-semibold" htmlFor="email">
          Enter your email
        </label>
        <p className="mb-4 text-sm text-gray-500">We'll send you a secure link to access your profile.</p>
        <form onSubmit={handleEmailSubmit}>
          <Input
            type="email"
            className=""
            placeholder="Your email address"
            {...bindController(controller)("email")}
          />
          <Button className="w-full mt-4" disabled={emailSent || sendingEmail} spinner={sendingEmail}>
            Send me a link
          </Button>
        </form>
      </div>
    </Layout>
  );
};

const ActivationPage = ActivationPageController.scope(ApplicationView(ControlledActivationPage));
export { ActivationPage };
