import { GroupDotName, Spinner } from "@mixitone/components/Controls";
import ClubPlayer from "@mixitone/models/ClubPlayer";
import { Player, Night } from "@mixitone/models";
import { ApplicationView } from "@mixitone/mvc";
import { formatRelativeTime, mapValues, withStateToggle } from "@mixitone/util";
import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import { AuthController } from "../Components/Authenticated";
import { DashLayout } from "../Components/DashLayout";
import { PortalController } from "../lib/PortalController";
import portalFunctions from "../lib/portalFunctions";

interface State {
  loading: boolean;
  clubPlayers: ClubPlayer[];
  loadingLastPlayed: boolean;
  lastPlayed: Record<string, string>;
}

class LandingPageController extends PortalController<State> {
  get initialState(): State {
    return {
      loading: true,
      clubPlayers: [],
      loadingLastPlayed: true,
      lastPlayed: {},
    };
  }

  async initialize() {
    await this.loadClubs();
    await this.loadLastPlayed();
  }

  @withStateToggle("loading")
  async loadClubs() {
    const clubPlayers = this.get(AuthController).state.clubPlayers;
    this.state.clubPlayers = clubPlayers.filter((cp) => cp.club);
  }

  @withStateToggle("loadingLastPlayed")
  async loadLastPlayed() {
    const lastPlayed = await portalFunctions.getPlayerLastPlayedAt({});
    this.state.lastPlayed = lastPlayed.lastPlayed;
  }
}

const ControlledLandingPage: React.FC = () => {
  const controller = LandingPageController.use();
  const { clubPlayers, lastPlayed, loadingLastPlayed } = controller.state;

  return (
    <DashLayout title="Your clubs">
      <div className="space-y-4">
        {clubPlayers.map((clubPlayer) => (
          <div key={clubPlayer.uniqueId} className="p-6 bg-white rounded-lg shadow-sm shadow-black/25">
            <div className="mb-4 text-lg font-medium">{clubPlayer.club.name}</div>
            <div className="">
              {clubPlayer.group && <GroupDotName group={clubPlayer.group} />}
              <div className="p-2">
                {!loadingLastPlayed && (
                  <>
                    {!lastPlayed[clubPlayer.club_id!] && <p>You've never played here</p>}
                    {lastPlayed[clubPlayer.club_id!] && (
                      <p>You played here {formatRelativeTime(lastPlayed[clubPlayer.club_id!])}</p>
                    )}
                  </>
                )}
                {loadingLastPlayed && (
                  <div className="flex h-4 gap-2 mt-1 mb-3 animate-pulse">
                    <div className="w-10 bg-gray-200 rounded" />
                    <div className="w-20 bg-gray-200 rounded" />
                    <div className="w-8 bg-gray-200 rounded" />
                    <div className="w-12 bg-gray-200 rounded" />
                  </div>
                )}
                <Link to={`/in/club/${clubPlayer.club_id}`} className="text-blue-500">
                  View club
                </Link>
              </div>
            </div>
          </div>
        ))}
        {clubPlayers.length === 0 && (
          <div className="p-6 bg-white rounded-lg shadow-sm shadow-black/25">
            <p>You are not a member of any clubs yet.</p>
            <p>Already part of a club? Contact your club admin to add your email address</p>
            <p className="text-center">OR</p>
            <p>Use your club sticker to sign in on a supported Android or iOS device.</p>
          </div>
        )}
      </div>
    </DashLayout>
  );
};

const LandingPage = LandingPageController.scope(ApplicationView(ControlledLandingPage));
export { LandingPage };
