import { Club, ClubPlayer, Group, RecurringSession } from "@mixitone/models";
import { withStateToggle } from "@mixitone/util/concerns";
import { formatDate } from "@mixitone/util/util";
import { AuthController } from "../../Components/Authenticated";
import { PortalController } from "../../lib/PortalController";
import portalFunctions from "../../lib/portalFunctions";

interface State {
  loadingGroups: boolean;
  loadingGames: boolean;
  loadingSessions: boolean;
  clubId: string;
  clubPlayer: ClubPlayer;
  club: Club;
  groups: Group[];
  group: Group;
  canUpdateGroup: boolean;
  updatingGroup: boolean;
  games: {
    date: string;
    games: {
      index: number;
      players: { name: string; group: Group }[];
    }[];
  }[];
  sessions: RecurringSession[];
}

interface Props {
  params: { clubId: string };
}

export class ClubDetailsPageController extends PortalController<State, Props> {
  get initialState(): State {
    return {
      loadingGroups: true,
      loadingGames: true,
      loadingSessions: true,
      sessions: [],
      clubId: "",
      clubPlayer: new ClubPlayer({}),
      club: new Club({}),
      groups: [],
      group: new Group({}),
      canUpdateGroup: true,
      updatingGroup: false,
      games: [],
    };
  }

  async initialize(props: Props) {
    this.state.clubId = props.params.clubId;
    const clubPlayers = this.get(AuthController).state.clubPlayers;
    const clubPlayer = clubPlayers.find((cp) => cp.club_id === this.state.clubId);

    if (!clubPlayer) {
      this.navigate("/in/home");
      return;
    }

    this.state.clubPlayer = clubPlayer;
    this.state.club = clubPlayer.club;
    await this.loadSessions();
    await this.loadGroups();
    await this.loadGames();
  }

  @withStateToggle("loadingSessions")
  async loadSessions() {
    this.state.sessions = await RecurringSession.query().eq("club_id", this.state.clubId).all();
  }

  @withStateToggle("loadingGroups")
  async loadGroups() {
    await this.state.club.groups.load();
    this.state.groups = this.state.club.groups;
    this.state.group = this.state.clubPlayer.group ?? this.state.groups[0];
  }

  @withStateToggle("loadingGames")
  async loadGames() {
    const games = await portalFunctions.getPlayerGames({
      clubPlayerId: this.state.clubPlayer.uniqueId,
    });
    this.state.games = games.games.reduce<State["games"]>((acc, game) => {
      console.log(game);
      const date = formatDate(game.started_at);
      console.log(date);
      if (acc[acc.length - 1]?.date !== date) {
        acc.push({ date, games: [] });
      }
      acc[acc.length - 1].games.push({
        index: game.index,
        players: game.players
          .filter((player) => player.id !== this.state.clubPlayer.uniqueId)
          .map((player) => {
            return {
              ...player,
              group: this.state.groups.find((group) => group.uniqueId === player.group_id) ?? new Group({}),
            };
          }),
      });

      return acc;
    }, []);
  }

  @withStateToggle("updatingGroup")
  async actionUpdateGroup(group: Group) {
    this.state.clubPlayer.group = group;
    await portalFunctions.updatePlayerGroup({
      clubPlayerId: this.state.clubPlayer.uniqueId,
      groupId: group.uniqueId,
    });
    await this.state.clubPlayer.reload();
    await this.state.clubPlayer.group.load();
    this.state.group = this.state.clubPlayer.group ?? this.state.groups[0];
  }
}
