import { ApplicationView } from "@mixitone/mvc";
import React from "react";
import { DashLayout } from "../Components/DashLayout";
import { injectParams } from "../lib/injectParams";
import { ClubDetailsPageController } from "./ClubDetailsPage/ClubDetailsPageController";
import { ClubGroup } from "./ClubDetailsPage/ClubGroup";
import { GameHistory } from "./ClubDetailsPage/GameHistory";
import { Panel } from "@mixitone/components/Controls";

const ControlledClubDetailsPage: React.FC = () => {
  const controller = ClubDetailsPageController.use();
  const { club, sessions } = controller.state;

  return (
    <DashLayout title={club.name}>
      <div className="flex flex-col gap-6 pb-6">
        {club.address && (
          <Panel title="Club details" titleClassName="font-semibold">
            {club.address}
          </Panel>
        )}
        <Panel title="Session times" titleClassName="font-semibold">
          {sessions.map((session, idx) => (
            <div key={idx}>{session.title}</div>
          ))}
        </Panel>
        <ClubGroup />
        <GameHistory />
      </div>
    </DashLayout>
  );
};

const ClubDetailsPage = injectParams(
  ClubDetailsPageController.scope(ApplicationView(ControlledClubDetailsPage)),
);
export { ClubDetailsPage };
