import ApplicationController from "@mixitone/mvc";
import { AppController } from "../App";
import { NavigateOptions } from "react-router-dom";
import { createSupabase } from "../supabaseClient";

export class PortalController<State extends object, Props = {}> extends ApplicationController<State, Props> {
  supabase!: ReturnType<typeof createSupabase>;

  constructor(...args: any[]) {
    // @ts-ignore
    super(...args);
    this.supabase = createSupabase();
  }

  get appController() {
    return this.get(AppController);
  }

  navigate(to: string, options?: NavigateOptions) {
    this.appController.navigate(to, options);
  }
}
