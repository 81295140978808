import { Button } from "@mixitone/components";
import { ApplicationView } from "@mixitone/mvc";
import { getJwt } from "@mixitone/util";
import { withStateToggle } from "@mixitone/util/concerns";
import { MessageBanner } from "../Components/MessageBanner";
import { PortalController } from "../lib/PortalController";
import portalFunctions from "../lib/portalFunctions";

interface ExtraAuthState {
  canUpdateProfile: boolean;
  canSendSignInEmail: boolean;
  sendingSignInEmail: boolean;
  message: React.ReactNode;
  messageType: "success" | "error" | null;
}

export class ExtraAuthController extends PortalController<ExtraAuthState> {
  get initialState(): ExtraAuthState {
    return {
      canUpdateProfile: false,
      canSendSignInEmail: false,
      sendingSignInEmail: false,
      message: null,
      messageType: null,
    };
  }

  async initialize() {
    await this.checkAmrClaim();
    await this.checkCanSendSignInEmail();
  }

  async checkAmrClaim() {
    const jwt = await getJwt(this.supabase);
    if (jwt) {
      const amr = jwt.amr;
      this.state.canUpdateProfile = !(amr && amr.length === 1 && amr[0].method === "nfc");
    } else {
      this.state.canUpdateProfile = false;
    }
  }

  async checkCanSendSignInEmail() {
    const {
      data: { user },
    } = await this.supabase.auth.getUser();
    const userEmail = user?.email;
    this.state.canSendSignInEmail = !!userEmail && !userEmail.includes("portal.mixitone.com");
  }

  @withStateToggle("sendingSignInEmail")
  async actionSendSignInEmail() {
    const {
      data: { user },
    } = await this.supabase.auth.getUser();
    if (!user || !this.state.canSendSignInEmail) return;

    try {
      const response = await portalFunctions.sendSignInEmail({
        signInWith: {
          uid: user.id,
          redirectTo: window.location.pathname,
        },
      });

      this.state.message = "Sign-in email sent successfully. Please check your inbox.";
      this.state.messageType = "success";

      if (response?.action_link) {
        this.state.message = (
          <>
            Sign-in email sent successfully. Please check your inbox.{" "}
            <a href={response.action_link}>Click here to sign in</a>.
          </>
        );
      }
    } catch (error) {
      console.error("Error sending sign-in email:", error);
      this.state.message = "Error sending sign-in email. Please try again.";
      this.state.messageType = "error";
    }
  }

  actionClearMessage() {
    this.state.message = null;
    this.state.messageType = null;
  }
}

const ControlledExtraAuthComponent: React.FC = () => {
  const controller = ExtraAuthController.use();
  const { canUpdateProfile, canSendSignInEmail, sendingSignInEmail, message, messageType } = controller.state;

  if (canUpdateProfile) return null;

  const handleSendSignInEmail = async () => {
    await controller.actionSendSignInEmail();
  };

  return (
    <>
      {message && messageType && (
        <MessageBanner message={message} type={messageType} onClose={() => controller.actionClearMessage()} />
      )}
      {!message && (
        <div className="p-4 mb-4 text-yellow-800 bg-yellow-100 border border-yellow-400 rounded shadow shadow-yellow-800/20">
          <p>You need additional authentication to update your profile.</p>
          {canSendSignInEmail ? (
            <Button spinner={sendingSignInEmail} onClick={handleSendSignInEmail} className="mt-2">
              Send Sign-In Email
            </Button>
          ) : (
            <p className="mt-2">Please contact your club admin to add or update your email address.</p>
          )}
        </div>
      )}
    </>
  );
};

export const ExtraAuthComponent = ExtraAuthController.scope(ApplicationView(ControlledExtraAuthComponent));
