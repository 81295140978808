import HomeIcon from "@mixitone/components/icons/club.svg";
import SignOutIcon from "@mixitone/components/icons/signout.svg";
import UserIcon from "@mixitone/components/icons/user.svg";
import ClubPlayer from "@mixitone/models/ClubPlayer";
import { ApplicationView } from "@mixitone/mvc";
import { decodeJwt } from "@mixitone/util/supabase";
import { AuthChangeEvent, User } from "@supabase/supabase-js";
import React from "react";
import { Link, Outlet } from "react-router-dom";
import { PortalController } from "../lib/PortalController";
import { createSupabase } from "../supabaseClient";

interface State {
  authed: boolean;
  sessionChecked: boolean;
  authedByTag: boolean;
  loading: boolean;
  user?: User;
  clubPlayers: ClubPlayer[];
}

export class AuthController extends PortalController<State> {
  get initialState(): State {
    return {
      authed: false,
      sessionChecked: false,
      authedByTag: false,
      loading: true,
      clubPlayers: [],
    };
  }

  async initialize() {
    this.state.sessionChecked = localStorage.getItem("sessionChecked") === "true";

    this.supabase = createSupabase();
    this.supabase.auth.onAuthStateChange((event) => {
      this.handleAuthEvent(event);
    });
    this.observe(() => this.loadClubPlayers());
  }

  handleAuthEvent(event: AuthChangeEvent) {
    switch (event) {
      case "SIGNED_IN":
        this.checkSession();
        break;
      case "SIGNED_OUT":
        this.checkSession();
        break;
      case "INITIAL_SESSION":
        this.checkSession();
        break;
    }
  }

  async checkSession() {
    const response = await this.supabase.auth.getSession();
    if (response.error) {
      console.log(response.error);
      this.navigate("/");
      return;
    }
    if (!response.data.session?.user) {
      console.log("No user in session");
      this.navigate("/");
      return;
    }

    const session = response.data.session;
    const user = session.user;
    const jwt = decodeJwt(session.access_token);

    if (!this.state.sessionChecked) {
      localStorage.setItem("sessionChecked", "true");
    }

    this.setState({
      authed: true,
      sessionChecked: true,
      user,
      authedByTag: jwt.amr && jwt.amr.length === 1 && jwt.amr[0].method === "nfc",
    });
  }

  async loadClubPlayers() {
    if (!this.state.authed) {
      this.state.clubPlayers = [];
      return;
    }

    const { data } = await this.supabase.auth.getSession();
    if (!data?.session?.user) {
      return;
    }

    const clubPlayers = await ClubPlayer.query()
      .eq("user_id", data.session.user.id)
      .preload("group", "club")
      .all();
    this.state.clubPlayers = clubPlayers;
    this.state.loading = false;
  }

  async actionLogOut() {
    await this.supabase.auth.signOut();
    this.navigate("/");
  }
}

const ControlledAuthenticated: React.FC = () => {
  const controller = AuthController.use();
  const { loading } = controller.state;

  if (loading) {
    return <></>;
  }

  return (
    <>
      <Outlet />
      <div className="fixed top-2 flex w-full max-w-md items-center justify-end gap-2 px-2 *:fill-slate-300 *:stroke-slate-300 *:text-slate-300">
        <Link to="/in/home" className="flex flex-col items-center text-xs hover:fill-white hover:text-white">
          <HomeIcon className="w-5 h-6" />
          <span>Clubs</span>
        </Link>
        <Link
          to="/in/profile"
          className="flex flex-col items-center text-xs hover:stroke-white hover:text-white"
        >
          <UserIcon className="w-6 h-6" />
          <span>Profile</span>
        </Link>
        <button
          onClick={() => controller.actionLogOut()}
          className="flex flex-col items-center text-xs hover:stroke-white hover:text-white"
        >
          <SignOutIcon className="w-6 h-6" />
          <span>Sign out</span>
        </button>
      </div>
    </>
  );
};

const Authenticated = AuthController.scope(ApplicationView(ControlledAuthenticated));
export { Authenticated };
