import { Panel, Spinner } from "@mixitone/components/Controls";
import TickIcon from "@mixitone/components/icons/tick.svg";
import { Group } from "@mixitone/models";
import { ApplicationView } from "@mixitone/mvc";
import clsx from "clsx";
import { useCallback, useState } from "react";
import { GroupDropdown } from "../../Components/GroupDropdown";
import { ClubDetailsPageController } from "./ClubDetailsPageController";

export const ClubGroup = ApplicationView(() => {
  const controller = ClubDetailsPageController.use();
  const { groups, clubPlayer, loadingGroups, updatingGroup, canUpdateGroup } = controller.state;
  const group = clubPlayer.group;
  const [didUpdate, setDidUpdate] = useState(false);

  const handleGroupChange = useCallback((group: Group) => {
    setDidUpdate(false);
    controller.actionUpdateGroup(group).then(() => {
      setDidUpdate(true);
    });
  }, []);

  return (
    <Panel title="Your group" titleClassName="font-semibold">
      {loadingGroups && <Spinner size={64} />}
      {!loadingGroups && (
        <div className="relative flex items-center">
          <GroupDropdown
            disabled={!canUpdateGroup}
            spinner={updatingGroup}
            className="w-full"
            groups={groups}
            value={group}
            onChange={handleGroupChange}
          />
          <TickIcon
            className={clsx(
              "pointer-events-none absolute right-4 w-[20px] stroke-green-600 transition-opacity",
              {
                "fade-in-out": didUpdate,
                hidden: !didUpdate,
              },
            )}
          />
        </div>
      )}
      {!canUpdateGroup && (
        <p className="mt-2 text-sm text-gray-500">Contact your club administrator to update your group</p>
      )}
      {canUpdateGroup && (
        <p className="mt-2 text-sm text-gray-500">
          Your club administrator has given you permission to update your group
        </p>
      )}
    </Panel>
  );
});
