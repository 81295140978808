import { Model, ModelAttributes, model, modelConfig, reference } from "@mixitone/oom";
import AccountUser from "./AccountUser";
import Night from "./Night";
import { setAccountId } from "./concerns/SetAccountId";

const config = modelConfig(
  {
    account_id: {
      type: "string",
    },
    created_at: {
      type: "datetime",
    },
    incident_description: {
      type: "string",
    },
    incident_occurred_at: {
      type: "datetime",
    },
    is_deleted: {
      type: "boolean",
    },
    logged_by_account_user_id: {
      type: "string",
    },
    night_id: {
      type: "string",
    },
    updated_at: {
      type: "datetime",
    },
  },
  {},
  {
    night: reference<Night>(() => Night, "night_id"),
    loggedByAccountUser: reference<AccountUser>(() => AccountUser, "logged_by_account_user_id"),
  },
);

interface IncidentLog extends ModelAttributes<typeof config> {}

@setAccountId
@model("incident_logs", config)
class IncidentLog extends Model<typeof config> {
  override canDestroy() {
    // cannot destroy if more than an hour has passed since the incident was logged
    if (this.created_at) {
      return Date.now() - this.created_at.getTime() < 60 * 60 * 1000;
    }
    return true;
  }
}

export default IncidentLog;
