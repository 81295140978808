import { ListSkeleton } from "./ListSkeleton";
import clsx from "clsx";

interface Props {
  Icon: any;
  loading?: boolean;
  itemCount?: number;
  size?: "sm" | "md" | "lg";
}

const EmptyState: React.FC<React.PropsWithChildren<Props>> = ({
  Icon,
  loading,
  itemCount,
  children,
  ...props
}) => {
  const size = props.size ?? "md";

  if (loading) {
    return (
      <div className="group-[.panel]:-translate-y-5">
        <ListSkeleton itemCount={itemCount ?? 3} />
      </div>
    );
  }

  return (
    <div
      className={clsx("h-50 flex flex-col items-center gap-2 py-10 text-slate-500", {
        "text-xs": size === "sm",
        "text-base": size === "md",
        "text-lg": size === "lg",
      })}
    >
      <Icon
        className={clsx("mx-auto fill-slate-200 stroke-slate-200", {
          "w-[40px]": size === "sm",
          "w-[60px]": size === "md",
          "w-[140px]": size === "lg",
        })}
      />
      {children}
    </div>
  );
};

export { EmptyState };
