import { Model, ModelAttributes, collection, model, modelConfig, reference } from "@mixitone/oom";
import { Nullable, formatTime, isUndefined } from "@mixitone/util";
import Night from "./Night";
import dayjs from "dayjs";
import Club from "./Club";
import { setAccountId } from "./concerns/SetAccountId";

const weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const config = modelConfig(
  {
    account_id: {
      type: "string",
    },
    club_id: {
      type: "string",
    },
    courts: {
      type: "number",
    },
    end_time: {
      type: "string",
    },
    is_deleted: {
      type: "boolean",
    },
    player_fee: {
      type: "number",
    },
    start_time: {
      type: "string",
    },
    updated_at: {
      type: "datetime",
    },
    warm_up_time: {
      type: "number",
    },
    weekday: {
      type: "number",
    },
  },
  {
    nights: collection<Night>(() => Night, "session_id", { order: "date" }),
  },
  {
    club: reference<Club>(() => Club, "club_id"),
  },
);

interface RecurringSession extends ModelAttributes<typeof config> {}

@setAccountId
@model("recurring_sessions", config)
class RecurringSession extends Model<typeof config> {
  static override tableName: "recurring_sessions" = "recurring_sessions";
  static override config = config;

  static WEEKDAYS = weekDays;

  get title() {
    if (!this.weekday) return "";

    let title = `${weekDays[this.weekday - 1]}s`;
    if (this.start_time) {
      title += ` at ${formatTime(this.start_time)}`;
    }
    if (this.end_time) {
      title += ` - ${formatTime(this.end_time)}`;
    }
    return title;
  }

  async start() {
    if (!this.persisted) throw new Error("Cannot start unsaved recurring session");

    await this.club.load();
    const night = await Night.createAndSetup(this.nightAttributes);

    if (!night.token) throw new Error("Could not create night");
    return night.token;
  }

  get nightAttributes() {
    return {
      courts: this.courts || 5,
      player_fee: this.player_fee,
      game_set_time: 15,
      warm_up_time: 10,
      session_id: this.id,
      club_id: this.club_id,
      account_id: this.account_id,
      auto_fill: true,
    };
  }

  private _activeNight: Nullable<Night> = undefined;
  private _previousNight: Nullable<Night> = undefined;

  setPreviousAndActiveNights(nights: Night[]) {
    if (nights.length === 0) {
      this._activeNight = null;
      this._previousNight = null;
      return;
    } else if (dayjs(nights[0].date).isSame(dayjs(), "day")) {
      this._activeNight = nights[0];
      this._previousNight = nights[1] ?? null;
      return;
    } else {
      this._activeNight = null;
      this._previousNight = nights[0];
      return;
    }
  }

  get activeNight() {
    if (!isUndefined(this._activeNight)) {
      return this._activeNight;
    } else if (this.nights.loaded) {
      return this.nights.find((night) => dayjs(night.date).isSame(dayjs(), "day"));
    } else {
      return null;
    }
  }

  get previousNight() {
    if (!isUndefined(this._previousNight)) {
      return this._previousNight;
    } else if (this.nights.loaded) {
      return this.nights.find((night) => dayjs(night.date).isBefore(dayjs(), "day"));
    } else {
      return null;
    }
  }
}

export default RecurringSession;
