export const Layout: React.FC<React.PropsWithChildren<{ title: React.ReactNode }>> = ({
  title,
  children,
}) => {
  return (
    <div className="flex flex-col w-full h-full max-w-md overflow-y-hidden ">
      <div className="p-4 text-2xl font-bold text-white rounded-t-lg bg-brand-700">{title}</div>
      <div className="p-6 bg-white rounded-b-lg shadow-xl">{children}</div>
    </div>
  );
};
